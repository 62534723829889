import React, { useState, useCallback, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Input,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { useDropzone } from "react-dropzone";
import { createGame } from "../api";
import AddIcon from "@mui/icons-material/Add";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UserContext from "../Context/UserContext";
import { toast } from "react-toastify";
import Header from "../Components/Header";
import LinkIcon from "@mui/icons-material/Link";
const SubmitGame = () => {
  const [gameName, setGameName] = useState("");
  const [downloadLink, setdownloadLink] = useState("");
  const [gameGenre, setGameGenre] = useState("");
  const [gameFeatures, setGameFeatures] = useState("");
  const [gameImage, setGameImage] = useState("");
  const [gameAbout, setGameAbout] = useState("");
  const [is18Plus, setIs18Plus] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes

    // Check if the file size is greater than the allowed maximum
    if (file && file.size > maxSize) {
      alert("File size exceeds the maximum limit of 10MB");
      return; // Do not proceed further
    }

    // If the file size is within the limit, set the gameImage and previewUrl
    setGameImage(file);

    // Read the image data and convert it to a base64-encoded string
    const reader = new FileReader();
    reader.onload = (event) => {
      setPreviewUrl(event.target.result); // Set the base64-encoded string
    };
    reader.readAsDataURL(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleSubmit = async () => {
    if (!gameName || !gameGenre || !gameFeatures || !gameAbout || !gameImage) {
      toast.error("Kindly fill all inputs");
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData(); // Create a new FormData object
      formData.append("gameName", gameName);
      formData.append("gameGenre", gameGenre);
      formData.append("gameFeatures", gameFeatures);
      formData.append("gameAbout", gameAbout);
      formData.append("is18Plus", is18Plus);
      formData.append("link", downloadLink);
      formData.append("gameImage", gameImage); // Append the file data to the FormData object
      formData.append("uploadedBy", user.id); // Assuming you have the user ID in user.id

      const response = await createGame(formData);

      if (response.status == 201) {
        // Clear the form fields
        setGameName("");
        setGameGenre("");
        setGameFeatures("");
        setGameImage("");
        setGameAbout("");
        setIs18Plus(false);

        toast.success(response.data.msg || "Game added successfully");
      } else {
        toast.error(response.data.msg || "Failed to add game");
      }
    } catch (error) {
      console.error("Error adding game:", error);
      toast.error(error.response.data.msg);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid py-5 dark-bg">
        <div className="container  py-5">
          <h2 className="text-center text-white" id="form-dialog-title">
            Submit Game For Review
          </h2>
          <DialogContent className=" text-white">
            <div className="row d-flex">
              <div className="col-md-6">
                <TextField
                  className="my-3"
                  fullWidth
                  label="Game Name"
                  variant="outlined"
                  value={gameName}
                  onChange={(e) => setGameName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ color: "white" }}>
                        <SportsEsportsIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />

                <FormControl fullWidth variant="outlined" className="my-3">
                  <InputLabel className="text-white">Game Genre</InputLabel>
                  <Select
                    value={gameGenre}
                    onChange={(e) => setGameGenre(e.target.value)}
                    label="Game Genre"
                    className="text-white"
                  >
                    {/* Replace the options with your actual game genres */}
                    <MenuItem value="Action">Action</MenuItem>
                    <MenuItem value="Adventure">Adventure</MenuItem>
                    <MenuItem value="RPG">RPG</MenuItem>
                    {/* Add more genres as needed */}
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className="my-3">
                  <InputLabel className="text-white">Game Features</InputLabel>
                  <Select
                    value={gameFeatures}
                    onChange={(e) => setGameFeatures(e.target.value)}
                    label="Game Features"
                    className="text-white"
                  >
                    {/* Replace the options with your actual game features */}
                    <MenuItem value="Multiplayer">Multiplayer</MenuItem>
                    <MenuItem value="Singleplayer">Singleplayer</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                    {/* Add more features as needed */}
                  </Select>
                </FormControl>
                <TextField
                  className="my-3"
                  fullWidth
                  label="Download Link"
                  variant="outlined"
                  value={downloadLink}
                  onChange={(e) => setdownloadLink(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <IconButton style={{ color: "white" }}>
                        <LinkIcon />
                      </IconButton>
                    ),
                  }}
                  InputLabelProps={{
                    className: "text-white",
                  }}
                  required
                />
              </div>
              <div className="col-md-6">
                <InputLabel className="text-white">
                  Game Image or Video
                </InputLabel>
                <div
                  role="button"
                  style={{
                    borderRadius: 8,
                    border: "2px dashed gray",
                    textAlign: "center",
                  }}
                >
                  <div
                    {...getRootProps()}
                    className="py-5"
                    style={{ display: "inline-block" }}
                  >
                    <input {...getInputProps()} />
                    {gameImage ? (
                      <>
                        {gameImage.type.startsWith("image/") ? (
                          // Display image preview
                          <img
                            src={previewUrl}
                            alt="Preview"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: 200,
                            }}
                          />
                        ) : gameImage.type.startsWith("video/") ? (
                          // Display video preview
                          <video
                            src={previewUrl}
                            controls
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: 200,
                            }}
                          />
                        ) : (
                          // Default message when no file is selected
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <FileUploadIcon className="mt-5 mb-2" />
                        {isDragActive ? (
                          <p>Drop the files here ...</p>
                        ) : (
                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <p className=" mt-1">Max:10MB</p>
              </div>
            </div>
            <TextField
              className="my-3"
              fullWidth
              label="Game About"
              variant="outlined"
              value={gameAbout}
              onChange={(e) => setGameAbout(e.target.value)}
              InputLabelProps={{
                className: "text-white",
              }}
              multiline
              rows={4}
            />

            <FormControlLabel
              control={
                <Checkbox
                  className="light-bg"
                  checked={is18Plus}
                  onChange={(e) => setIs18Plus(e.target.checked)}
                  color="primary"
                />
              }
              label="Is this Game 18+"
              className="text-white"
            />
          </DialogContent>
          <DialogActions className=" text-white">
            <Button
              onClick={handleSubmit}
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              disabled={isLoading}
              fullWidth
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "white" }} size={27} />
              ) : (
                " Submit Game"
              )}
            </Button>
          </DialogActions>
        </div>
      </div>
    </>
  );
};

export default SubmitGame;
