import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import Header from "../Components/Header";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Handle form submission here
    // You can use the name, email, phone, and message state values
    // Reset the state values after submission if needed
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setOpen(false); // Close the dialog after submission
  };

  return (
    <div>
      <Header />
      <div className="container-fluid py-5 dark-bg">
        <div className="container py-5">
          <DialogContent className="text-white row align-items-center">
            <div className="col-md-5">
              <h2 className="text-center text-white" id="form-dialog-title">
                Contact Us
              </h2>
              <TextField
                className="my-3 text-white"
                fullWidth
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <IconButton className="text-white">
                      <PersonIcon />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  className: "text-white", // Set the label color to white
                }}
              />
              <TextField
                className="my-3 text-white"
                fullWidth
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <IconButton className="text-white">
                      <EmailIcon />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  className: "text-white", // Set the label color to white
                }}
              />
              <TextField
                className="my-3 text-white"
                fullWidth
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
                InputProps={{
                  startAdornment: (
                    <IconButton className="text-white">
                      <PhoneIcon />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  className: "text-white", // Set the label color to white
                }}
              />
              <TextField
                className="my-3 text-white"
                fullWidth
                label="Message"
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                required
                InputProps={{
                  startAdornment: (
                    <IconButton className="text-white">
                      <MessageIcon />
                    </IconButton>
                  ),
                }}
                InputLabelProps={{
                  className: "text-white", // Set the label color to white
                }}
              />
              <DialogActions className="text-white">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  startIcon={<SendIcon />}
                  color="primary"
                  fullWidth
                >
                  Send
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Message Sent</DialogTitle>
                  <DialogContent>
                    Your message has been sent successfully.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </DialogActions>
            </div>
            <div className="col-md-6 p-5">
              <img className="w-100" src="/contact.png" alt="Contact" />
            </div>
          </DialogContent>
        </div>
      </div>
    </div>
  );
};

export default Contact;
