import React from "react";
import Herobg from "../../Assets/homepagehero.jpg";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";

export default function Hero({Herobg}) {
  return (
    <div className="hero-container">
      <div className="hero-bg" style={{ backgroundImage: `url(${Herobg})` }} />
      <div className="hero-overlay"></div>
      <div className="hero-content container mt-auto">

      </div>
    </div>
  );
}
