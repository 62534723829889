import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
export default function Footer() {
  return (
    <div className="py-2 container-fluid light-bg">
      <div className="py-4 container">
        <div className=" footer-upper-menu">
          <span role="button" className="mx-2">
            Home
          </span>
          <span role="button" className="mx-2">
            Top Movies
          </span>
          <span role="button" className="mx-2">
            Top Shows
          </span>
          <span role="button" className="mx-2">
            Top Games
          </span>
        </div>
        <div
          className="border-top row my-5 py-4 "
          style={{ position: "relative" }}
        >
          <div
            className="bg-white rounded-5 py-2 px-4"
            style={{ width: "150px", position: "absolute", top: -23, right: 0 }}
          >
            <FacebookIcon className="text-dark mx-1" />
            <TwitterIcon className="text-dark mx-1" />
            <LinkedInIcon className="text-dark mx-1" />
          </div>
          <div className="col-md-3">
            <h4>Location</h4>
            <p className="text-muted">New York,Us</p>
          </div>
          <div className="col-md-3">
            <h4>Phone</h4>
            <p className="text-muted">0232323231</p>
          </div>
          <div className="col-md-3">
            <h4>Email</h4>
            <p className="text-muted">company@gmail.com</p>
          </div>
          <div className="col-md-3 mt-auto pb-3">
            Copyright © 2023 All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  );
}
