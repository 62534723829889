import React, { useContext, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";
import {
  Slider,
  Typography,
  Button,
  Modal,
  TextField,
  LinearProgress,
  Grid,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { createReview } from "../../api";
import { toast } from "react-toastify";
import UserContext from "../../Context/UserContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a custom theme with customized warning color
const theme = createTheme({
  palette: {
    warning: {
      main: "#f0dc04",
    },
  },
});
export default function GameMedia({ game, onAdd }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [gameplay, setGameplay] = useState(10);
  const [story, setStory] = useState(10);
  const [visuals, setVisuals] = useState(10);
  const [sound, setSound] = useState(10);
  const [controls, setControls] = useState(10);
  const [replayability, setReplayability] = useState(10);
  const [originality, setOriginality] = useState(10);
  const [value, setValue] = useState(10);
  const [technicalPerformance, setTechnicalPerformance] = useState(10);
  const [accessibility, setAccessibility] = useState(10);
  const [comment, setComment] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(UserContext);
  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };

  const handleOpenModal = () => {
    if (user) {
      setModalOpen(true);
    } else {
      toast.error("Login to add the review.");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("gameplay", gameplay);
      formData.append("story", story);
      formData.append("visuals", visuals);
      formData.append("sound", sound);
      formData.append("controls", controls);
      formData.append("replayability", replayability);
      formData.append("originality", originality);
      formData.append("value", value);
      formData.append("technicalPerformance", technicalPerformance);
      formData.append("accessibility", accessibility);
      formData.append("comment", comment);
      formData.append("attachment", attachment);
      formData.append("game", game._id);

      const res = await createReview(formData);

      if (res) {
        console.log(res);
        toast.success(res.data.msg);
        onAdd();
        handleCloseModal();
      }
    } catch (error) {
      toast.error("Error While adding rewview.");

      console.log("Error while Adding Review.", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container pt-5 pb-2">
      <div className="row my-3 d-flex align-items-stretch">
        <div className="col-lg-2 col-md-4 col-sm-6 my-2">
          <div className="thumbnail-wrapper ">
            <div
              className="image-container rounded-3 d-flex align-items-end justify-content-start"
              style={{
                height: "180px",
                maxWidth: "100%",
                overflow: "hidden",
                backgroundImage: `url("${
                  process.env.REACT_APP_BACKEND + game.image
                }")`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
          </div>
        </div>
        <div className="col-12 d-flex align-items-center justify-content-between">
          <h2 className="my-5 col-sm-6">{game.gameName}</h2>
          <Button onClick={handleOpenModal} className="" variant="contained">
            Add Review
          </Button>
        </div>

        <div className="mb-2 text-muted col-12 d-flex justify-content-start align-items-center">
          <Avatar
            alt={game.uploadedBy?.username}
            src=""
            className="me-2"
            style={{
              width: "35px",
              height: "35px",
              boxShadow: "inset 0 0 0 1px white",
            }}
          />
          {game.uploadedBy?.username}
          <FiberManualRecordIcon
            className="me-2 ms-4"
            style={{ fontSize: "10px" }}
          />
          {game.gameFeatures}
          <FiberManualRecordIcon
            className="me-2 ms-4"
            style={{ fontSize: "10px" }}
          />
          {new Date(game.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </div>
        <ThemeProvider theme={theme}>
          <Modal open={modalOpen} onClose={handleCloseModal}>
            <div
              className="light-bg p-4 rounded-4 col-lg-5 col-md-7 col-11"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
              }}
            >
              <h3 className="text-center col-12">How's your experience?</h3>
              <p className="text-center col-12 text-muted">
                Provide your ratings and general review for the game.
              </p>
              <Grid container spacing={2}>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Game Play</Typography>
                    <p>{gameplay}</p>
                  </div>
                  <Slider
                    value={gameplay}
                    onChange={handleSliderChange(setGameplay)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Story</Typography>
                    <p>{story}</p>
                  </div>
                  <Slider
                    value={story}
                    onChange={handleSliderChange(setStory)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>

                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Visuals</Typography>
                    <p>{visuals}</p>
                  </div>
                  <Slider
                    value={visuals}
                    onChange={handleSliderChange(setVisuals)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Sound</Typography>
                    <p>{sound}</p>
                  </div>
                  <Slider
                    value={sound}
                    onChange={handleSliderChange(setSound)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Controls</Typography>
                    <p>{controls}</p>
                  </div>
                  <Slider
                    value={controls}
                    onChange={handleSliderChange(setControls)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Replayability</Typography>
                    <p>{replayability}</p>
                  </div>
                  <Slider
                    value={replayability}
                    onChange={handleSliderChange(setReplayability)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                {/* Repeat similar code blocks for Originality, Value, Technical Performance, Accessibility */}
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Originality</Typography>
                    <p>{originality}</p>
                  </div>
                  <Slider
                    value={originality}
                    onChange={handleSliderChange(setOriginality)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Value</Typography>
                    <p>{value}</p>
                  </div>
                  <Slider
                    value={value}
                    onChange={handleSliderChange(setValue)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Technical Performance</Typography>
                    <p>{technicalPerformance}</p>
                  </div>
                  <Slider
                    value={technicalPerformance}
                    onChange={handleSliderChange(setTechnicalPerformance)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
                <Grid className="my-2" item xs={6}>
                  <div className="col-12 my-0 py-0 d-flex align-items-center justify-content-between">
                    <Typography gutterBottom>Accessibility</Typography>
                    <p>{accessibility}</p>
                  </div>
                  <Slider
                    value={accessibility}
                    onChange={handleSliderChange(setAccessibility)}
                    color="warning"
                    defaultValue={10}
                    max={10}
                  />
                </Grid>
              </Grid>

              <input
                type="file"
                className="form-control w-100 bg-dark text-white"
                accept=".jpg, .jpeg, .png, .gif, .mp4" // Accepted file types (images and videos)
                onChange={(e) => {
                  const selectedFile = e.target.files[0];

                  // Check file size
                  if (selectedFile && selectedFile.size > 10 * 1024 * 1024) {
                    alert("File size exceeds the maximum limit of 10MB");
                    return;
                  }

                  setAttachment(selectedFile);
                }}
              />
              <TextField
                className="my-3"
                fullWidth
                label="Add Review"
                variant="outlined"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                InputProps={{
                  style: {
                    color: "white", // Change text color to white
                    borderColor: "white", // Change border color to white
                  },
                }}
                InputLabelProps={{
                  className: "text-white", // Change label text color to white
                }}
                multiline
                rows={4}
              />
              <Button
                variant="contained"
                fullWidth
                className="py-2"
                color="primary"
                disabled={isLoading}
                onClick={() => handleSubmit()}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "white" }} size={27} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </Modal>
        </ThemeProvider>
      </div>
    </div>
  );
}
