import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Rating,
  Stack,
  Chip,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const UserReview = ({ reviews }) => {
  return (
    <div className="container">
      <h2>Featured Reviews</h2>
      {reviews?.map((review, index) => (
        <Card className=" dark-bg text-white" key={index} sx={{ mb: 2 }}>
          <div className="row py-3 " style={{ borderBottom: "1px solid gray" }}>
            <CardHeader
              className="col-lg-3 mb-auto col-md-4 col-12"
              avatar={
                <Avatar src={review.user?.profile} alt={review.user?.profile} />
              }
              title={review.user?.username}
              subheader={new Date(review.createdAt).toLocaleString()}
              subheaderTypographyProps={{
                variant: "subtitle2",
                color: "white",
              }} // Customize the typography style
            />
            <CardContent className="col-lg-9 col-md-8 col-12">
              <Typography variant="body1" sx={{ mb: 1 }}>
                {review.rating}
              </Typography>
              <Typography variant="body2">{review.review}</Typography>
              {review?.attachment ? (
                <Stack direction="row" className="my-2" spacing={1}>
                  <a
                    target="_blank"
                    href={process.env.REACT_APP_BACKEND + review.attachment}
                  >
                    {" "}
                    <Chip
                      role="button"
                      icon={<OpenInNewIcon className="text-white" />}
                      label="Open Attachment"
                      variant="outlined"
                      className="text-white"
                    />
                  </a>
                </Stack>
              ) : (
                ""
              )}
            </CardContent>
          </div>
        </Card>
      ))}
      {/* <div className="text-center my-5 ">
        <Link>View More Reviews</Link>
      </div> */}
    </div>
  );
};

UserReview.propTypes = {
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      userAvatar: PropTypes.string.isRequired,
      userName: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
      comment: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default UserReview;
