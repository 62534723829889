import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND + "/api", // Update the base URL to match your backend API address
});
// Get the token from localStorage
var token = localStorage.getItem("playercritic_user_Token");

// Add the Authorization header to the Axios instance if the token exists
if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token.replace(
    /^"|"$/g,
    ""
  )}`;
}
// Coach APIs
export const getCoachByUsername = async (username) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/@${username}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export function getAllCoaches() {
  return api.get("/coaches");
}

export function getCoachById(coachId) {
  return api.get(`/coaches/${coachId}`);
}

export function createCoach(coachData) {
  return api.post("/coaches", coachData);
}

export function updateCoach(coachId, coachData) {
  return api.put(`/coaches/${coachId}`, coachData);
}

export function getCoachIdByEmail(email) {
  return api.get(`/coaches/email/${email}`);
}
export function getCoachLevel(coachId) {
  return api.get(`/coaches/${coachId}/level`);
}
export function getCoachAverageRating(coachId) {
  return api.get(`/coaches/${coachId}/average-rating`);
}
// Game APIs
export function createGame(gameData) {
  return api.post("/games", gameData);
}

export const getGameByFriendlyUrl = async (friendlyUrl) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/${friendlyUrl}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export function getGameById(gameId) {
  return api.get(`/games/${gameId}`);
}
export function addGameView(gameId) {
  return api.get(`/games/count/${gameId}`);
}
export function getPopularGames(gameId) {
  return api.get(`/games/popular/${gameId}`);
}

export function updateGame(gameId, gameData) {
  return api.put(`/games/${gameId}`, gameData);
}
export function randomGame() {
  return api.post(`/games/random`);
}
export function deleteGame(GameData) {
  return api.post("/games/delete", GameData);
}
export function blockGame(GameData) {
  return api.post("/games/block", GameData);
}
export function unBlockGame(GameData) {
  return api.post("/games/un-block", GameData);
}
export function approveGame(GameData) {
  return api.post("/games/approve", GameData);
}
export function rejectGame(GameData) {
  return api.post("/games/reject", GameData);
}
export function getAllGames(gameData) {
  return api.post("/games/all", gameData);
}
export function searchGameByUser(gameData) {
  return api.post("/games/mygames", gameData);
}
export function searchGame(gameData) {
  return api.post("/games/search", gameData);
}
export function trendingToday(gameData) {
  return api.post("/games/trending", gameData);
}
export function getPendingGames(gameData) {
  return api.post("/games/pending", gameData);
}

// Message APIs
export function sendMessage(orderId, messageData) {
  return api.post(`/messages/${orderId}`, messageData);
}

export function getMessages(orderId) {
  return api.get(`/messages/${orderId}`);
}

export function deleteMessagesByOrderId(orderId) {
  return api.delete(`/messages/${orderId}`);
}

export function deleteMessageById(messageId) {
  return api.delete(`/messages/message/${messageId}`);
}

// Order APIs
export function createOrder(orderData) {
  return api.post("/orders", orderData);
}

export function getOrders() {
  return api.get("/orders");
}

export function getOrder(orderId) {
  return api.get(`/orders/${orderId}`);
}

export function updateOrder(orderId, orderData) {
  return api.put(`/orders/${orderId}`, orderData);
}

export function deleteOrder(orderId) {
  return api.delete(`/orders/${orderId}`);
}

// Service APIs
export function createService(coachId, serviceData) {
  return api.post(`/coaches/${coachId}/services`, serviceData);
}

export function updateService(coachId, serviceId, serviceData) {
  return api.put(`/coaches/${coachId}/services/${serviceId}`, serviceData);
}

export function getService(coachId, serviceId) {
  return api.get(`/coaches/${coachId}/services/${serviceId}`);
}
export function getAllService() {
  return api.get(`/services/all`);
}

export function getServicesByCoach(coachId) {
  return api.get(`/coaches/${coachId}/services`);
}

export function deleteService(coachId, serviceId) {
  return api.delete(`/coaches/${coachId}/services/${serviceId}`);
}

// User APIs
export function registerUser(userData) {
  return api.post("/users/register", userData);
}

export function loginUser(userData) {
  return api.post("/users/login", userData);
}
export function loginAdmin(userData) {
  return api.post("/users/adminlogin", userData);
}

export function getUserProfile() {
  return api.get("/users/profile");
}
export function getAllUserProfiles(userData) {
  return api.post("/users/all", userData);
}

export function updateUserProfile(userData) {
  return api.put("/users/profile", userData);
}
export function updateUserPassword(userData) {
  return api.put("/users/password", userData);
}

export function deleteUser(userData) {
  return api.post("/users/delete", userData);
}
export function blockUser(userData) {
  return api.post("/users/block", userData);
}
export function unBlockUser(userData) {
  return api.post("/users/un-block", userData);
}

export function generateAffiliateLink() {
  return api.get("/users/affiliate/link");
}

export function getAffiliateDashboard() {
  return api.get("/users/affiliate/dashboard");
}

// Review APIs
export function createReview(reviewData) {
  return api.post("/reviews", reviewData);
}
export function getReviews() {
  return api.get("/reviews");
}
export function getReview(id) {
  return api.get("/reviews/" + id);
}
export function getReviewById(reviewId) {
  return api.get(`/reviews/${reviewId}`);
}
export function updateReview(reviewId, reviewData) {
  return api.put(`/reviews/${reviewId}`, reviewData);
}
export function getReviewsByGameId(gameId) {
  return api.get(`/reviews/game/${gameId}`);
}

//Task APIs
export function createTask(taskData) {
  // New function to create a task
  return api.post("/tasks", taskData);
}
export function updateTask(taskId, taskData) {
  // New function to update a task
  return api.put(`/tasks/${taskId}`, taskData);
}
export function getTasksByCustomer(customerId) {
  // New function to get tasks by customer ID
  return api.get(`/tasks/customer/${customerId}`);
}
export function getTaskById(taskId) {
  // New function to get a task by its ID
  return api.get(`/tasks/${taskId}`);
}
export function deleteTask(taskId) {
  // New function to delete a task by its ID
  return api.delete(`/tasks/${taskId}`);
}
