import React, { useState } from "react";
import { Typography, Modal, LinearProgress, Grid } from "@mui/material";
import TopGames from "./TopGames";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Create a custom theme with customized warning color
const theme = createTheme({
  palette: {
    warning: {
      main: "#f0dc04",
    },
  },
});
const GameMedia = ({ gamedata }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const ratings = [
    { item: "Game Play", rating: gamedata?.featureRatings?.gameplay * 10 },
    { item: "Story", rating: gamedata?.featureRatings?.story * 10 },
    { item: "Visuals", rating: gamedata?.featureRatings?.visuals * 10 },
    { item: "Sounds", rating: gamedata?.featureRatings?.sound * 10 },
    { item: "Controls", rating: gamedata?.featureRatings?.controls * 10 },
    {
      item: "Replayability",
      rating: gamedata?.featureRatings?.replayability * 10,
    },
    {
      item: "Originality",
      rating: gamedata?.featureRatings?.originality * 10,
    },
    { item: "Value", rating: gamedata?.featureRatings?.value * 10 },
    {
      item: "Technical Performance",
      rating: gamedata?.featureRatings?.technicalPerformance * 10,
    },
    {
      item: "Accessibility",
      rating: gamedata?.featureRatings?.accessibility * 10,
    },
  ];

  return (
    <div className="container py-2">
      <div className="row my-3 d-flex align-items-stretch">
        {[
          { d: "Moderate", t: "Dificulty Level" },
          { d: "34 Hours", t: "Hours of Game Play" },
          { d: gamedata.rating, t: "Player Critic Score" },
          { d: "9.9", t: "User Score" },
        ].map((game, index) => (
          <div className="col-lg-3 my-2 col-md-4 col-6" key={index}>
            <div
              style={{ height: "180px" }}
              onClick={handleOpenModal}
              role="button"
              className="thumbnail-wrapper light-bg  text-center  rounded-3 flex-wrap d-flex align-items-center justify-content-center "
            >
              <div>
                <h4 className="col-12 text-muted">{game.t}</h4>
                <p className="col-12">{game.d}</p>
              </div>
            </div>
          </div>
        ))}
        <h2 className="mt-4 mb-2">About</h2>
        <div className="mb-2 row">
          <p className="text-white col-md-9 ">{gamedata.gameAbout}</p>
          <div className="col-md-3">
            <TopGames />
          </div>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            className="light-bg p-4 rounded-4 col-lg-5     col-md-7 col-11"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",

              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h5 className="text-muted">Ratings</h5>
            <p className="text-muted">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. dolor
              temporibus eveniet.
            </p>
            <Grid container spacing={2}>
              {ratings.map((rating, index) => (
                <>
                  <Grid className="my-2" item xs={6} key={index}>
                    <div className="d-flex justify-content-between">
                      <Typography gutterBottom>{rating.item}</Typography>
                      <Typography variant="body2" className="text-muted">
                        {rating.rating}%
                      </Typography>
                    </div>
                    <LinearProgress
                      variant="determinate"
                      color="warning"
                      value={rating.rating}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </Modal>
      </ThemeProvider>
    </div>
  );
};

export default GameMedia;
