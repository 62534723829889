import React, { useState, useEffect, useContext } from "react";
//import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import Home from "./Home.png";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import axios from "axios";
import jwtDecode from "jwt-decode";
import logo from "../Components/logo.png";
// import { UserContext } from "../Context/UserContext";
import { loginAdmin } from "../api";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bg from "../Assets/auth-bg.png";
import PersonIcon from "@mui/icons-material/Person";
import { registerUser } from "../api";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import AppleIcon from "@mui/icons-material/Apple";
import UserContext from "../Context/UserContext";
import ThirdPartyLogin from "../Components/Auth/ThirdPartyLogin";
export default function Signup() {
  //   const { token, handleSetUserAndToken } = useContext(UserContext);

  const [email, setemail] = useState("");
  const [username, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [uniqueId, setUniqueId] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const [loginError, setloginError] = useState("");
  const [isProcessing, setisProcessing] = useState(false);
  const { setUserAndToken } = useContext(UserContext);
  useEffect(() => {
    const token = localStorage.getItem("playercritic_user_Token");
    if (token) {
      navigate("/");
    }
  }, [navigate]);
  const handleGenerateUniqueId = () => {
    // Generate a random 8-character string for the unique ID
    const uniqueString = Math.random().toString(36).substring(2, 12);
    setUniqueId(uniqueString);
    return uniqueString;
  };

  const doSignup = async () => {
    setisProcessing(true);
    if (!username || !email || !password) {
      toast.error("Kindly Fill All Inputs");
      return;
    }

    try {
      var res = await registerUser({
        username,
        email,
        uniqueId: handleGenerateUniqueId(),
        password,
        type: "User",
        login: true,
      });

      // Handle the response from the backend if needed
      if (res.data) {
        const decoded = jwtDecode(res.data.token);
        localStorage.setItem("playercritic_user_Token", res.data.token);

        setUserAndToken(decoded.user, res.data.token);
        console.log("Decoded User Data", decoded);
        toast.success(res.data.msg);
        setName("");
        setemail("");
        setPassword("");
        setUniqueId("");
        navigate("/");
      }
    } catch (error) {
      // Handle the error and show the error message using toast
      console.error("Error occurred:", error);
      // If there are validation errors, display them in the toast
      if (error.response && error.response.data && error.response.data.errors) {
        const validationErrors = error.response.data.errors;
        validationErrors.forEach((err) => {
          toast.error(err.msg);
        });
      } else {
        toast.error(
          error.response.data.msg ||
            "An error occurred. Please try again later."
        );
      }
    } finally {
      setisProcessing(false);
    }
  };
  return (
    <div
      className="dark-bg d-flex justify-content-center align-items-center w-100"
      style={{
        minHeight: "100vh",
        position: "relative",
        background: `url(${bg})`,
        backgroundSize: "100% auto", // Set the background size
        backgroundRepeat: "no-repeat", // Prevent image repetition
      }}
    >
      {/* Add the overlay pseudo-element */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black overlay
        }}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="align-items-center py-5 rounded col-lg-4 col-md-5 col-sm-10">
        <Card
          className="p-4 rounded-4 w-100"
          style={{ backgroundColor: "rgba(17, 18, 24, 0.9)" }}
        >
          <div className="p-1 text-center">
            <h3 className="text-white w-100" style={{ opacity: 0.9 }}>
              Create an Account
            </h3>
            <p className="text-muted w-100" style={{ opacity: 0.9 }}>
              Enter your details or continue with Google,Facebook or Apple ID{" "}
            </p>
          </div>

          <TextField
            style={{ backgroundColor: "#fff", borderRadius: 15, marginTop: 20 }}
            className="mt-4"
            fullWidth
            id="outlined-basic"
            label="User Name"
            value={username}
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton style={{ color: "white" }}>
                  <PersonIcon />
                </IconButton>
              ),
            }}
            InputLabelProps={{
              className: "text-white",
            }}
          />

          <TextField
            style={{ backgroundColor: "#fff", borderRadius: 15, marginTop: 20 }}
            className="my-4"
            fullWidth
            id="outlined-basic"
            label="Email"
            value={email}
            variant="outlined"
            onChange={(e) => setemail(e.target.value)}
            InputProps={{
              startAdornment: (
                <IconButton style={{ color: "white" }}>
                  <EmailIcon />
                </IconButton>
              ),
            }}
            InputLabelProps={{
              className: "text-white",
            }}
          />

          <TextField
            style={{
              backgroundColor: "#fff",
              borderRadius: 15,
              marginBottom: 20,
            }}
            fullWidth
            value={password}
            id="outlined-basic"
            type={showPassword ? "text" : "password"}
            label="Password"
            onChange={handlePasswordChange}
            InputProps={{
              startAdornment: (
                <IconButton style={{ color: "white" }}>
                  <LockIcon />
                </IconButton>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    style={{ color: "white" }}
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              className: "text-white",
            }}
          />

          <Button
            fullWidth
            // style={{ backgroundColor: "#7b9858", color: "#fff" }}
            className="my-3 rounded-3 py-3"
            variant="contained"
            size="large"
            disabled={isProcessing}
            onClick={() => doSignup()}
          >
            {isProcessing ? (
              <CircularProgress sx={{ color: "white" }} size={27} />
            ) : (
              "Create Account"
            )}
          </Button>
          <ThirdPartyLogin />
          <div className="text-with-bars">
            <span className="horizontal-bar"></span>
            <h5 className="text text-white">OR</h5>
            <span className="horizontal-bar"></span>
          </div>
          <Button className="text-white">
            Already have an account?{" "}
            <Link className="ms-1" to="/login">
              {" "}
              Login
            </Link>
          </Button>
        </Card>
      </div>
    </div>
  );
}
