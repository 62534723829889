import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { Link, useParams } from "react-router-dom";
import { searchGameByUser } from "../api";
import Header from "../Components/Header";
import { toast } from "react-toastify";
import { Pagination, CircularProgress } from "@mui/material";

const ITEMS_PER_PAGE = 12;
export default function MyGames() {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalGames, setTotalGames] = useState(0);

  useEffect(() => {
    getData();
  }, [currentPage]);
  const getData = async () => {
    setIsLoading(true); // Set loading state
    try {
      const res = await searchGameByUser({
        page: currentPage,
        perPage: ITEMS_PER_PAGE,
      });
      if (res) {
        setGames(res.data.games);

        setTotalPages(Math.ceil(res.data.totalCount / ITEMS_PER_PAGE));
        setTotalGames(res.data.totalCount);
      } else {
        toast.error("Error While Fetching Game Data");
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false); // Data fetching complete, set loading state to false
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <Header />
      <div className="container py-5">
        <h4 className="text-white">{totalGames} Games Submitted By You </h4>
        {isLoading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
          <>
            <div className="row my-3 d-flex align-items-stretch">
              {games?.map((game, index) => {
                const url = process.env.REACT_APP_BACKEND + game.gameImage;

                return (
                  <div className="col-lg-3 my-2 col-md-6 col-sm-11" key={index}>
                    <div
                      className="thumbnail-wrapper d-flex flex-wrap light-bg"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="image-container w-100 d-flex align-items-end justify-content-start"
                        style={{
                          height: "370px",
                          maxWidth: "100%",
                          overflow: "hidden",
                          backgroundImage: `url(${url})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <div className="light-bg py-1 px-3 rounded-4 m-3">
                          <StarIcon
                            style={{ color: "yellow", fontSize: "15px" }}
                          />
                          <span className="text-muted star-rating">
                            {game.rating}
                          </span>
                        </div>
                      </div>

                      <div className="game-details w-100 m-3 mt-auto flex-column">
                        <h2 className="mt-1">{game.gameName}</h2>
                        <div className="my-3">
                          <span className="feature-icon">
                            <FiberManualRecordIcon
                              className="text-muted mx-1"
                              style={{ fontSize: "10px" }}
                            />
                          </span>
                          <span className="feature-text text-muted">
                            {game.gameFeatures}
                          </span>

                          <span className="feature-icon">
                            <FiberManualRecordIcon
                              className="text-muted mx-1"
                              style={{ fontSize: "10px" }}
                            />
                          </span>
                          <span className="feature-text text-muted">
                            {game.gameGenre}
                          </span>
                          <span className="feature-icon">
                            <FiberManualRecordIcon
                              className="text-muted mx-1"
                              style={{ fontSize: "10px" }}
                            />
                          </span>
                          <span className="feature-text text-muted">
                            {new Date(game.createdAt).getFullYear()}
                          </span>
                        </div>
                        <div className="mt-auto">
                          <Link
                            to={"/gamereview/" + game._id}
                            className="w-100 reviews-button-link"
                          >
                            <Button
                              fullWidth
                              variant="contained"
                              className="mb-3 py-2 reviews-button"
                            >
                              See Reviews
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="bg-white mt-3 d-flex justify-content-center py-1 pagination">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
              />
            </div>{" "}
          </>
        )}
      </div>
    </>
  );
}
