// theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "white",
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#111319",
            },
            "&:hover fieldset": {
              borderColor: "black",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
            backgroundColor: "#111319",
            color: "white",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiSelect-icon": {
            color: "white",
          },
          "&:before": {
            borderBottomColor: "white",
          },
          "&:after": {
            borderBottomColor: "white",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: "white",
          },
          "&:hover:not(.Mui-disabled):after": {
            borderBottomColor: "white",
          },
          backgroundColor: "#111319",
          color: "white",
        },
      },
    },
     MuiDialog: {
      styleOverrides: {
        paper: {
          maxWidth: "800px", // Adjust the value as per your requirement
          width: "100%",
          margin: "0 auto",
          borderRadius: "10px",
        },
      },
    },

  },
});

export default theme;
