import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { trendingToday } from "../../api";

export default function TrendingToday() {
  const [games, setGames] = useState([]);
  useEffect(() => {
    const getGames = async () => {
      try {
        var res = await trendingToday();
        if (res) {
          console.log(res.data.data);
          setGames(res.data.data);
        }
      } catch (error) {
        console.log("Failed to get trending Games", error);
      }
    };
    getGames();
  }, []);

  return (
    <div className="container py-5">
      <h2>Trending Today</h2>
      <div className="row my-3 d-flex align-items-stretch">
        {games?.map((game, index) => {
          const url = process.env.REACT_APP_BACKEND + game.gameImage;

          return (
            <div className="col-lg-3 my-2 col-md-6 col-sm-11" key={index}>
              <div
                className="thumbnail-wrapper d-flex flex-wrap light-bg"
                style={{ height: "100%" }}
              >
                <div
                  className="image-container w-100 d-flex align-items-end justify-content-start"
                  style={{
                    height: "370px",
                    maxWidth: "100%",
                    overflow: "hidden",
                    backgroundImage: `url(${url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div className="light-bg py-1 px-3 rounded-4 m-3">
                    <StarIcon style={{ color: "yellow", fontSize: "15px" }} />
                    <span className="text-muted star-rating">
                      {game.rating}
                    </span>
                  </div>
                </div>

                <div className="game-details w-100 m-3 mt-auto flex-column">
                  <h2 className="mt-1">{game.gameName}</h2>
                  <div className="my-3">
                    <span className="feature-icon">
                      <FiberManualRecordIcon
                        className="text-muted mx-1"
                        style={{ fontSize: "10px" }}
                      />
                    </span>
                    <span className="feature-text text-muted">
                      {game.gameFeatures}
                    </span>

                    <span className="feature-icon">
                      <FiberManualRecordIcon
                        className="text-muted mx-1"
                        style={{ fontSize: "10px" }}
                      />
                    </span>
                    <span className="feature-text text-muted">
                      {game.gameGenre}
                    </span>
                    <span className="feature-icon">
                      <FiberManualRecordIcon
                        className="text-muted mx-1"
                        style={{ fontSize: "10px" }}
                      />
                    </span>
                    <span className="feature-text text-muted">
                      {new Date(game.createdAt).getFullYear()}
                    </span>
                  </div>
                  <div className="mt-auto">
                    <Link
                      to={"/gamereview/" + game._id}
                      className="reviews-button-link"
                    >
                      <Button
                        fullWidth
                        variant="contained"
                        className="mb-3 py-2 reviews-button"
                      >
                        See Reviews
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
