import React, { useState, useEffect, useContext } from "react";
//import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import Home from "./Home.png";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import axios from "axios";
import jwtDecode from "jwt-decode";
import logo from "../Components/logo.png";
import UserContext from "../Context/UserContext";
import { loginUser } from "../api";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bg from "../Assets/auth-bg.png";
import ThirdPartyLogin from "../Components/Auth/ThirdPartyLogin";

export default function Login() {
  const { user, setUserAndToken } = useContext(UserContext);
  const [email, setemail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();
  const [loginError, setloginError] = useState("");
  const [isProcessing, setisProcessing] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);
  const doLogin = async (e) => {
    if (!password || !email) {
      toast.error("Kindly fill all inputs");
      return;
    }

    try {
      setisProcessing(true);
      const response = await loginUser({ email, password });
      console.log("Res", response);
      if (response.status == 200) {
        console.log(response);
        localStorage.setItem(
          "playercritic_user_User",
          JSON.stringify(response.data.user)
        );

        const decoded = jwtDecode(response.data.token);
        console.log("User", decoded);

        localStorage.removeItem("playercritic_user_Token");
        localStorage.removeItem("playercritic_user_User");
        localStorage.setItem("playercritic_user_Token", response.data.token);
        localStorage.setItem("playercritic_user_User", decoded);
        setUserAndToken(decoded.user, response.data.token);

        if (response.status == 200) {
          window.location.href = "/";
        }
      } else {
        toast.error("Invalid Email or Password");
      }
      // Save the token and userId to the local storage or state management system
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.msg || "Error while logging in");
    } finally {
      setisProcessing(false);
    }
  };
  return (
    !user && (
      <div
        className="dark-bg d-flex justify-content-center align-items-center w-100"
        style={{
          minHeight: "100vh",
          position: "relative",
          background: `url(${bg})`,
          backgroundSize: "100% auto", // Set the background size
          backgroundRepeat: "no-repeat", // Prevent image repetition
        }}
      >
        {/* Add the overlay pseudo-element */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Semi-transparent black overlay
          }}
        />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="align-items-center rounded py-5 col-lg-4 col-md-5 col-sm-10">
          <Card
            className="p-4 rounded-4 w-100"
            style={{ backgroundColor: "rgba(17, 18, 24, 0.9)" }}
          >
            <div className="p-1 text-center">
              <h3 className="text-white w-100" style={{ opacity: 0.9 }}>
                Welcome Back
              </h3>
              <p className="text-muted w-100" style={{ opacity: 0.9 }}>
                Enter your details or continue with Google,Facebook or Apple ID{" "}
              </p>
            </div>
            {loginError && <Alert severity="error">{loginError}</Alert>}

            <TextField
              style={{
                backgroundColor: "#fff",
                borderRadius: 15,
                marginTop: 20,
              }}
              className="my-4"
              fullWidth
              id="outlined-basic"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => setemail(e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton style={{ color: "white" }}>
                    <EmailIcon />
                  </IconButton>
                ),
              }}
              InputLabelProps={{
                className: "text-white",
              }}
            />

            <TextField
              style={{
                backgroundColor: "#fff",
                borderRadius: 15,
                marginBottom: 20,
              }}
              fullWidth
              value={password}
              id="outlined-basic"
              type={showPassword ? "text" : "password"}
              label="Password"
              onChange={handlePasswordChange}
              InputProps={{
                startAdornment: (
                  <IconButton style={{ color: "white" }}>
                    <LockIcon />
                  </IconButton>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "white" }}
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                className: "text-white",
              }}
            />

            <Button
              fullWidth
              // style={{ backgroundColor: "#7b9858", color: "#fff" }}
              className="my-3 rounded-3 py-3"
              variant="contained"
              size="large"
              disabled={isProcessing}
              onClick={() => doLogin()}
            >
              {isProcessing ? (
                <CircularProgress sx={{ color: "white" }} size={27} />
              ) : (
                "Login"
              )}
            </Button>
            <ThirdPartyLogin />
            <div className="text-with-bars">
              <span className="horizontal-bar"></span>
              <h5 className="text text-white">OR</h5>
              <span className="horizontal-bar"></span>
            </div>
            <Link to="/signup">
              {" "}
              <Button
                fullWidth
                // style={{ backgroundColor: "#7b9858", color: "#fff" }}
                className="my-3 rounded-3 py-3 light-bg"
                variant="contained"
                size="large"

                // onClick={() => doLogin()}
              >
                Create an Account
              </Button>
            </Link>
          </Card>
        </div>
      </div>
    )
  );
}
