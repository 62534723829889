import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";

const games = [
  {
    title: "Avatar",
    image:
      "/avatar.png",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "2022",
  },
  {
    title: "Cyber Pank 2077",
    image: "/cyber.jpeg",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "2022",
  },

  {
    title: "Avatar",
    image:
      "/avatar.png",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "2022",
  },

  {
    title: "Cyber Pank 2077",
    image: "/cyber.jpeg",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "2022",
  },
];

export default function ComingSoon() {
  return (
    <div className="container py-5">
      <h2>Comming Soon To Theaters</h2>
      <div className="row my-3 d-flex align-items-stretch">
        {games.map((game, index) => (
          <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
            <div className="thumbnail-wrapper ">
              <div
                className="image-container rounded-3 d-flex align-items-end justify-content-start"
                style={{
                  height: "230px",
                  maxWidth: "100%",
                  overflow: "hidden",
                  backgroundImage: `url("${game.image}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="light-bg py-1 px-3 rounded-4 m-3">
                  <StarIcon style={{ color: "yellow", fontSize: "15px" }} />
                  <span className="text-muted star-rating">{game.rating}</span>
                </div>
              </div>

              <div className="game-details m-3">
                <div className="my-3">
                  <span className="feature-text text-muted">{game.year}</span>
                </div>

                <h5>{game.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
