import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getPopularGames } from "../../api";

export default function TopGames() {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const getGameData = async () => {
      try {
        var res = await getPopularGames(2);
        if (res) {
          setGames(res.data.popularGames);
        }
      } catch (error) {
        console.log("Failed to get Game Data", error);
      }
    };

    getGameData();
  }, []);
  return (
    <div className="container py-5">
      <h2>Top Games</h2>
      <div className="row my-3 d-flex align-items-stretch">
        {games.map((game, index) => (
          <div className="col-12 my-2" key={index}>
            <div
              className="thumbnail-wrapper  d-flex flex-wrap light-bg"
              style={{ height: "100%" }}
            >
              <div
                className="image-container w-100 d-flex align-items-end justify-content-start"
                style={{
                  height: "230px",
                  maxWidth: "100%",
                  overflow: "hidden",
                  backgroundImage: `url("${
                    process.env.REACT_APP_BACKEND + game.gameImage
                  }")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div className="light-bg py-1 px-3 rounded-4 m-3">
                  <StarIcon style={{ color: "yellow", fontSize: "15px" }} />
                  <span className="text-muted star-rating">{game.rating}</span>
                </div>
              </div>

              <div className="w-100 game-details mt-auto m-3">
                <h5 className="mt-1">{game.gameName}</h5>
                <div className="my-3">
                  {/* <span className="feature-icon">
                    <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                  </span> */}
                  <span className="feature-text text-muted">
                    {new Date(game.createdAt).getFullYear()}
                  </span>
                </div>
                <Link to={"/gamereview/" + game._id}>
                  <Button
                    fullWidth
                    variant="outlined"
                    className=" border-white text-white mb-3 py-2 reviews-button"
                  >
                    See Reviews
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
