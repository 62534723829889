import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import StarIcon from "@mui/icons-material/Star";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

const games = [
  {
    title: "Avatar",
    image:
      "/avatar.png",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "23 May 2022",
  },
  {
    title: "Cyber Pank 2077",
    image: "/cyber.jpeg",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "23 May 2022",
  },

  {
    title: "Avatar",
    image:
      "/avatar.png",
    rating: "4.5",
    features: ["Adventure", "Multiplayer"],
    year: "23 May 2022",
  },
];

export default function ComingSoon() {
  return (
    <div className="container py-5">
      <h2>Top News</h2>
      <div className="row my-3 d-flex align-items-stretch">
        {games.map((game, index) => (
          <div className="col-lg-4 col-md-6 col-sm-11" key={index}>
            <div className="thumbnail-wrapper d-flex my-3">
              <div
                className="col-5 p-2 image-container  d-flex align-items-end justify-content-start"
                style={{
                  height: "190px",
                  maxWidth: "100%",
                  overflow: "hidden",
                  backgroundImage: `url("${game.image}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>

              <div className="col-7 game-details m-3 p-2">
                <div className="my-3">
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Molestias quia blanditiis dolorem odit porro ab.
                  </p>
                  <span className="feature-text text-muted">
                    {game.year}{" "}
                    <FiberManualRecordIcon style={{ fontSize: "10px" }} /> Top
                    News{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="col-12">
          <Chip
            role="button"
            label="Movies"
            variant="outlined"
            className="my-1 mx-2 text-white"
          />
          <Chip
            role="button"
            label="News"
            variant="outlined"
            className="my-1 mx-2 text-white"
          />
          <Chip
            role="button"
            label="Top Movies"
            variant="outlined"
            className="my-1 mx-2 text-white"
          />
          <Chip
            role="button"
            label="Action Movies"
            variant="outlined"
            className="my-1 mx-2 text-white"
          />
        </div>
      </div>
    </div>
  );
}
