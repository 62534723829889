import React from "react";
import Button from "@mui/material/Button";

export default function Guide() {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-6">
          <img
            src="/guide.jpg"
            className="w-100 rounded-4"
            alt="Guide Image"
            srcset=""
          />
        </div>
        <div className="col-md-6 d-flex align-items-center flex-wrap p-5">
          <h1>The Best Guide To Start Cyber Punk</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Voluptatibus cum ut perspiciatis quam minima sequi facilis soluta
            sint? Repellat, suscipit assumenda. Ipsam cupiditate illo doloribus
            corporis, laboriosam libero iusto cumque. Quos aliquam recusandae
            quis maxime aspernatur non, quas magnam ipsum natus enim vero
            nostrum temporibus odio provident deleniti totam earum dignissimos
            quam! Sit porro perspiciatis et sunt quasi.
            <br></br>
            consequatur in dignissimos eius labore debitis deleniti, pariatur
            rem laboriosam quas veniam delectus saepe? Dolorum, amet voluptates
            veritatis, quod eaque.
          </p>
          <Button variant="contained" className="py-2 my-2">
            Watch Now
          </Button>
        </div>
      </div>
    </div>
  );
}
